import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Link from '@mui/material/Link';
import LinkIcon from '@mui/icons-material/Link';
import { RESOURCE_TYPE_URL } from 'utils/constants';
import { useGetFileFromS3 } from '../../../admin/program/resources/hooks/useGetFileFromS3';

interface LearnerCohortDashboardProps {
  displayName: string | null;
  name: string;
  programId: string;
  resourceTypeId: string | null;
  url: string | null;
}

const LearnerCohortDashboard = ({ displayName, name, programId, resourceTypeId, url }: LearnerCohortDashboardProps) => {
  const { getFile } = useGetFileFromS3();

  const downloadResource = async () => {
    await getFile(programId, name);
  }

  return (
    <>
      {resourceTypeId === RESOURCE_TYPE_URL ? (
        <LinkIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
      ) : (
        <FileDownloadIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
      )}

      <Link onClick={downloadResource} style={{ cursor: 'pointer'}}>
        {displayName ?? name}
      </Link>
    </>
  );
};

export default LearnerCohortDashboard;
